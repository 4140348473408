import React, { useEffect, useState } from 'react';
import { getScoreboard } from '../api/ctfdApi';
import './Scoreboard.css';

const Scoreboard = () => {
  const [scoreboard, setScoreboard] = useState([]);
  const [countdown, setCountdown] = useState(5);
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    const fetchScoreboard = async () => {
      const data = await getScoreboard();
      setScoreboard(data);
    };

    const calculateTimeRemaining = () => {
      const now = new Date();
      
      // Set the end time to 12 PM Las Vegas time on August 11, 2024
      const end = new Date(Date.UTC(2024, 7, 11, 19, 0, 0)); // 7 PM UTC is 12 PM PDT (Las Vegas)

      const timeDiff = end - now;

      if (timeDiff <= 0) {
        setTimeRemaining("CTF has ended");
      } else {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
      }
    };

    // Fetch the scoreboard initially and set up the countdown for the CTF end
    fetchScoreboard();
    calculateTimeRemaining();

    // Set up intervals to refresh the scoreboard and the countdown timer
    const scoreboardIntervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          fetchScoreboard();  // Refresh the scoreboard when the countdown reaches 1
          return 5; // Reset the countdown to 5 after refresh
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000); // Decrease the countdown every second

    const countdownIntervalId = setInterval(calculateTimeRemaining, 1000);

    // Clean up intervals when the component unmounts
    return () => {
      clearInterval(scoreboardIntervalId);
      clearInterval(countdownIntervalId);
    };
  }, []);

  const topTeams = scoreboard.slice(0, 10);
  const otherTeamsCount = scoreboard.length - topTeams.length;

  return (
    <div className="scoreboard-container">
      <div className="countdown-timer">
        Refreshing in: {countdown}
      </div>
      <h1 className="scoreboard-title">High Score</h1>
      <p><div className="ctf-countdown">
        Time remaining until end of the CTF: {timeRemaining}
      </div></p>
      
      <table className="scoreboard-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Team</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {topTeams.map((team, index) => {
            let rankClass = '';
            if (index === 0) {
              rankClass = 'first-place';
            } else if (index === 1) {
              rankClass = 'second-place';
            } else if (index === 2) {
              rankClass = 'third-place';
            }

            return (
              <tr key={team.id} className={rankClass}>
                <td>{index + 1}</td>
                <td>{team.name}</td>
                <td>{team.score}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <p className="other-teams-text">
        and {otherTeamsCount} other teams competing, can you make the scoreboard?<br></br>
        Join our CTF @ gamehackers.gg - WiFi (gamehackers-5g/gamehacking.gg)
      </p>
    </div>
  );
};

export default Scoreboard;
