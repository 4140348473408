import axios from 'axios';
import localData from '../Example.json'; // Import the local JSON file

// Global variable to control data source
const USE_LOCAL_DATA = false; // Set this to true to use local JSON file

const API_URL = 'https://score.gamehackingvillage.com/api/v1';
const CF_CLIENT_ID = '7b67d2d46b3f92fa600741af53290a10.access';
const CF_CLIENT_SECRET = 'a440f7da93e418ea844a1fc96903bceea76c13ddca68852ddc98ca604faa3578';
const API_TOKEN = 'ctfd_611d71407600ef28fb8122c8d264b1e44cfdd591729d56865411c1ef1b994091';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'CF-Access-Client-Id': CF_CLIENT_ID,
    'CF-Access-Client-Secret': CF_CLIENT_SECRET,
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${API_TOKEN}`,
  },
});

export const getScoreboard = async () => {
  if (USE_LOCAL_DATA) {
    try {
      // Return local data directly
      return localData;
    } catch (error) {
      console.error('Error loading local scoreboard data', error);
      return [];
    }
  } else {
    try {
      // Fetch data from API
      const response = await api.get('/scoreboard');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching scoreboard data from API', error);
      return [];
    }
  }
};
